import colors from 'constants/colors';
import styled from 'styled-components';

export const Container = styled.div`
  width: 32px;
  text-align: center;
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RoundContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;

  &:hover {
    background-color: ${colors.neutral200};
  }
`;
