import colors from 'constants/colors';
import styled from 'styled-components';

export const CreateLabelContainer = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  width: 100%;
`;

export const CreateLabelActionContainer = styled.div`
  display: flex;
  color: ${colors.gray500};
  margin-left: auto;
  order: 2;
`;

export const CreateLabelOptionTitle = styled.span``;
export const CreateLabelOptionAction = styled.span`
padding-left: 6px;
`;
