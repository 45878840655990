import { Moment } from 'moment';
import moment from 'moment-business-days';

import { format } from 'utils/number';
import i18n from '../i18n';

export function formatDurationDisplayMinutes(duration: number) {
  const _duration = moment.duration(duration, 'seconds');
  let durationDisplay = '0 min';
  if (!_duration || !_duration.isValid()) return durationDisplay;

  if (_duration.asMinutes() > 0 && _duration.asMinutes() < 60) {
    durationDisplay = `${_duration.minutes()} min`;
  } else if (_duration.asMinutes() >= 60 && _duration.asMinutes() < 60 * 24) {
    durationDisplay = `${_duration.hours()} h ${_duration.minutes()} min`;
  } else if (_duration.asMinutes() >= 60 * 24) {
    durationDisplay = `${Math.floor(_duration.asDays())}j ${_duration.hours()} h ${_duration.minutes()} m`;
  }

  return durationDisplay;
}

export function formatAsHoursMinutes(hours: number) {
  let durationDisplay = '0 h';
  if (hours === null) return durationDisplay;

  const duration = moment.duration(hours, 'hours');

  if (!duration || !duration.isValid()) return durationDisplay;
  durationDisplay = `0 h`;

  if (duration.asMinutes() > 0) {
    if (duration.asMinutes() % 60 !== 0) {
      durationDisplay = ` ${duration.minutes()} ${i18n.t('translation:common.timeInterval.minutes')}`;
    } else {
      durationDisplay = '';
    }
  }
  if (duration.asMinutes() >= 60) {
    durationDisplay = `${format(
      Math.floor(duration.asHours()),
      i18n.t('translation:common.thousandSeparator'),
      i18n.t('translation:common.decimalSeparator'),
    )} h${durationDisplay}`;
  }

  return durationDisplay;
}

/**
 *
 * @param {minutes} minutes
 */
// eslint-disable-next-line max-statements
export function humanizeInterval(minutes: number) {
  if (minutes === 0 || minutes === 1) {
    return i18n.t('translation:common.timeInterval.everyMin');
  }

  const time = moment.duration(minutes, 'minutes');

  const days = time.days();
  const hours = time.hours();
  const mins = time.minutes();

  if (days) {
    /*
      The below commented lines are here for i18next-parser
      t('translation:common.timeInterval.day', {'count': {{days}} })
      t('translation:common.timeInterval.hour', {'count': {{hours}} })
      t('translation:common.timeInterval.min', {'count': {{mins}} })
    */
    if (!hours && !mins) {
      return days === 1
        ? i18n.t('translation:common.timeInterval.everyDay')
        : i18n.t('translation:common.timeInterval.everyXDays', {
            days,
          });
    }

    if (hours && !mins) {
      return i18n.t('translation:common.timeInterval.everyXDaysYHours', {
        days,
        hours,
      });
    }

    if (!hours && mins) {
      return i18n.t('translation:common.timeInterval.everyXDaysYMins', {
        days,
        mins,
      });
    }

    if (hours && mins) {
      return i18n.t('translation:common.timeInterval.everyXDaysYHoursZMins', {
        days,
        hours,
        mins,
      });
    }
  }

  if (hours) {
    if (!mins) {
      return hours === 1
        ? i18n.t('translation:common.timeInterval.everyHour')
        : i18n.t('translation:common.timeInterval.everyXHours', { hours });
    } else {
      return i18n.t('translation:common.timeInterval.everyXHoursYMins', {
        hours,
        mins,
      });
    }
  }

  return i18n.t('translation:common.timeInterval.everyXMins', { mins });
}

export function humanizeDate(date: any, defaultLabel = '') {
  if (!date) return defaultLabel;
  return moment(date).format('DD/MM/YY LT');
}

export function humanizeTimeFromDate(date: any, defaultLabel = '') {
  if (!date) return defaultLabel;
  return moment(date).format('LT');
}

export function getDateShortFormat() {
  return 'DD/MM';
}

export function getDateLongFormat() {
  return 'DD/MM/YYYY';
}

export function getDateWithFormat(date: Date | string, format: string) {
  return moment(date).format(format);
}

export function formatDateShort(date: any) {
  if (!date) return '';
  return moment(date).format(getDateShortFormat());
}

export const getUTCStartOfDayDate = (date: Date) => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
};

export const getUTCEndOfDayDate = (date: Date) => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59),
  );
};

export const formatPreviousPeriod = (
  currentPeriodStart: Date,
  currentPeriodEnd: Date,
  format: string,
  separator: string = '-',
): string => {
  const periodLength = moment(currentPeriodEnd).diff(currentPeriodStart);
  const previousPeriodStart = moment(currentPeriodStart)
    .subtract(periodLength)
    .format(format);
  return `${previousPeriodStart} ${separator} ${moment(currentPeriodStart).format(format)}`;
};

export type PeriodScale = 'minute' | 'day' | 'week' | 'month' | 'year';
type DateFormatter = string | ((momentDate: moment.Moment) => string);

const defaultFormat = 'ddd Do MMM';
const scaleFormat: Record<PeriodScale, DateFormatter> = {
  minute: `${defaultFormat} HH:mm`,
  day: defaultFormat,
  week: (date: moment.Moment) => {
    const startOfWeek = date.clone().startOf('isoWeek');
    const endOfWeek = date.clone().endOf('isoWeek');
    return i18n.t('analytics:chart.tooltip.weekPeriod', {
      startOfWeek: startOfWeek.format('Do MMM'),
      endOfWeek: endOfWeek.format('Do MMM'),
    });
  },
  month: 'MMMM YY',
  year: 'YYYY',
};

export const formatDateWithPeriodScale = (
  date: string | number | Date | moment.Moment,
  periodScale: PeriodScale = 'day',
): string => {
  const format = scaleFormat[periodScale] || defaultFormat;
  const lang = i18n.resolvedLanguage || 'fr';
  switch (typeof format) {
    case 'function':
      return format(moment(date));
    default:
      return moment(date).locale(lang).format(format);
  }
};

// moment-business-days 1.2.0 is completely broken so we compute business days ourselves and while the bug has been
// fixed in their repo, 1.3.0 is not yet released and pointing directly to the repository in package.json breaks some
// tests because of an import in the library code…
// see https://github.com/eduolalo/moment-business-days/issues/109
export const computeBusinessDays = (
  startDate: Moment,
  endDate: Moment,
): number => {
  const businessDays = [1, 2, 3, 4, 5]; // monday to friday
  const currentDate = startDate.clone();
  let businessDaysCount = 0;
  if (currentDate.isAfter(endDate, 'day')) {
    return 0;
  }
  while (currentDate.isSameOrBefore(endDate, 'day')) {
    businessDaysCount += businessDays.includes(currentDate.day()) ? 1 : 0;
    currentDate.add(1, 'day');
  }
  return businessDaysCount;
};

export const getPeriodLabel = (since: Date, until: Date): string => {
  return `${moment(since).format(getDateLongFormat())} - ${moment(until).format(getDateLongFormat())}`;
};

export const getEndPeriodDate = (date: Date, periodScale: PeriodScale) => {
  return moment(date).endOf(periodScale).toDate();
};
