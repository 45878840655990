import colors from 'constants/colors';

type ChevronLightLeftProps = {
  size?: number;
  color?: string;
};
export const ChevronLightLeft = ({
  size = 16,
  color = colors.gray850,
}: ChevronLightLeftProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 14L5 8L11 2"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
