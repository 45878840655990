import colors from 'constants/colors';

type ChevronLightRightProps = {
  size?: number;
  color?: string;
};
export const ChevronLightRight = ({
  size = 16,
  color = colors.gray850,
}: ChevronLightRightProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 2L11 8L5 14"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
