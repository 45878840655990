import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'reducers';

type FaultEvents =
  | 'See original description'
  | 'See fault report'
  | 'See fault asset profile'
  | 'View switched';
type AlertEvents =
  | 'Alert created'
  | 'Alert updated'
  | 'Alert deleted'
  | 'Alert canceled';
type DestinationsPageEvents =
  | 'API key created'
  | 'API key removed'
  | 'API key rotated';
type DataKindEvents = 'Datatype selected';

export type EventName =
  | 'Column updated'
  | 'Column sorted'
  | 'Number of result changed'
  | 'Filter selected'
  | 'Period selected'
  | 'Report exported'
  | 'Switch report clicked'
  | 'Paywall CTA clicked'
  | 'Periodicity selected'
  | 'Report asset list lookup opened'
  | 'Segment created'
  | 'Segment applied'
  | 'Assetdocument uploaded'
  | 'Assetdocument downloaded'
  | 'Asset archived'
  | 'Asset unarchived'
  | 'Asset deleted'
  | 'Sharing created'
  | 'Sharing edited'
  | 'Sharing stopped'
  | 'AIcategorysuggestion displayed'
  | 'AIcategorysuggestion selected'
  | DestinationsPageEvents
  | FaultEvents
  | AlertEvents
  | DataKindEvents;

export function isHibooUser(email: string) {
  return email.endsWith('@hiboo.io');
}

export function buildContext(
  user:
    | {
        email?: string | null;
        workspaceId?: number | null;
      }
    | undefined,
) {
  if (!user) return {};
  const { email, workspaceId } = user;
  if (!email || !workspaceId || isHibooUser(email)) return {};

  return {
    workspaceId,
  };
}

export function useTracking() {
  const user = useSelector((state: Store) => state.user);

  const track = useCallback(
    (eventName: EventName, properties: Record<string, any>) => {
      analytics.track(eventName, properties, {
        context: buildContext(user),
      });
    },
    [user],
  );

  return { track };
}
