import React from 'react';
import Select, { GroupBase, StylesConfig } from 'react-select';

import { Category, CheckIcon } from 'modules/common-ui';

import colors from 'constants/colors';
import { SingleOption } from './index.css';
import { buildCategoryOptions, buildCustomStyle } from './utils';

export type CategoryType = {
  id: string;
  name: string;
};

export type CategoryPickerProps = {
  value?: CategoryType | null;
  id?: string;
  categories: CategoryType[];
  isValid?: boolean;
  onChange: (value: CategoryType | null) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  placeholder: string;
  styles?: Partial<StylesConfig<CategoryType, false, GroupBase<CategoryType>>>;
  showIndicator?: boolean;
  showSelectedCategoryCSSTransition?: boolean;
  [propName: string]: any;
  components?: any;
};

export const CategoryPicker = ({
  value,
  id,
  categories,
  isValid = true,
  onChange,
  onBlur,
  placeholder,
  styles = {},
  showIndicator = false,
  showSelectedCategoryCSSTransition = false,
  components = {},
  ...rest
}: CategoryPickerProps) => {
  const categoryOptions = buildCategoryOptions(categories);

  const Option = ({ innerProps, isFocused, data }: any) => {
    const isSelected = data.id === value?.id;
    return (
      <SingleOption
        {...innerProps}
        isFocused={isFocused}
        isSelected={isSelected}
      >
        <Category fullSize category={data} isOption={true} />
        {isSelected && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CheckIcon color={colors.gray850} />
          </div>
        )}
      </SingleOption>
    );
  };

  const SingleValue = (props: any) => (
    <Category
      fullSize
      category={props.data}
      showTextTransition={showSelectedCategoryCSSTransition}
    />
  );

  return (
    <Select
      inputId={id}
      components={{
        ...components,
        Option,
        SingleValue,
        ...(!showIndicator && {
          IndicatorSeparator: null,
        }),
      }}
      isClearable={true}
      maxMenuHeight={300}
      closeMenuOnSelect={true}
      value={value}
      options={categoryOptions}
      menuPlacement="auto"
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      styles={{ ...buildCustomStyle(!isValid), ...styles }}
      {...rest}
    />
  );
};
