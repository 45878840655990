import colors from 'constants/colors';
import { buildCommonStyle } from 'modules/common-ui/styles/Select/commonStyle';
import { Theme } from 'react-select';
import styled from 'styled-components';

export function buildCustomStyle(hasError: boolean = false) {
  const commonStyle = buildCommonStyle(hasError);
  return {
    ...commonStyle,
    control: (provided: any, state: any) => ({
      ...commonStyle.control(provided, state),
      cursor: 'text',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      cursor: 'pointer',
      color: colors.gray850,
      backgroundColor: state.isSelected ? colors.gray850Hover : colors.white,
      '&:hover': {
        backgroundColor: colors.gray850Hover,
      },
    }),
  };
}

export function buildCustomTheme(theme: Theme) {
  const themeColors = theme.colors;
  return {
    ...theme,
    colors: {
      ...themeColors,
      primary: colors.gray50,
      primary25: colors.gray850Hover,
      primary50: colors.gray850Hover,
    },
  };
}

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;
