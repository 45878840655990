import { Cross } from '../Icons';
import { Container, RoundContainer } from './style.css';

const Indicator = () => (
  <Container>
    <RoundContainer>
      <Cross />
    </RoundContainer>
  </Container>
);

export const SelectClearIndicator = (props: any) => {
  const { children = <Indicator />, innerProps } = props;
  return <div {...innerProps}>{children}</div>;
};
