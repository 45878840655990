import {
  CreateLabelActionContainer,
  CreateLabelContainer,
  CreateLabelOptionAction,
  CreateLabelOptionTitle,
} from './style.css';

type SelectCreateLabelProps = {
  inputValue: string;
  text: string;
  icon?: React.ReactNode;
};

export const SelectCreateLabel = (props: SelectCreateLabelProps) => {
  return (
    <CreateLabelContainer>
      <CreateLabelOptionTitle>“{props.inputValue}”</CreateLabelOptionTitle>
      <CreateLabelActionContainer>
        {props.icon}
        <CreateLabelOptionAction>{props.text}</CreateLabelOptionAction>
      </CreateLabelActionContainer>
    </CreateLabelContainer>
  );
};
