/**
 * Password regex to ensure at least 80 bits of entropy
 * - At least one digit
 * - At least one lowercase letter
 * - At least one uppercase letter
 * - At least one special character
 * - At least 12 characters long
 */
export const passwordRegex = new RegExp(
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{12,}$/,
);
