import colors, { neutralColors } from 'constants/colors';
import fontSizes from 'constants/fontSizes';
import * as Highcharts from 'highcharts';
import HC_exportData from 'highcharts/modules/export-data';
import HC_exporting from 'highcharts/modules/exporting';
import { TFunction } from 'i18next';

// Enable highchart exporting module
HC_exporting(Highcharts);
// Enable CSV exporting module
HC_exportData(Highcharts);

export const setHCTranslations = (t: TFunction<'commonUi'>) => {
  Highcharts.setOptions({
    lang: {
      resetZoom: t('chart.resetZoom'),
    },
  });
};

export const HCDefaultOptions: Highcharts.Options = {
  exporting: {
    buttons: {
      contextButton: {
        enabled: false,
      },
    },
  },
  chart: {
    backgroundColor: 'transparent',
    reflow: true,
    zooming: {
      type: 'x',
      resetButton: {
        theme: {
          fill: colors.white,
          borderColor: neutralColors.main,
          r: '5px',
          color: neutralColors.main,
          states: {
            hover: {
              fill: neutralColors.light,
              borderColor: neutralColors.medium,
            },
          },
        },
      },
    },
    style: {
      fontFamily: 'Inter',
    },
  },
};

export const HCDefaultTooltipOptions: Highcharts.TooltipOptions = {
  shape: 'square',
  backgroundColor: colors.gray850,
  borderColor: 'transparent',
  shadow: false,

  style: {
    color: colors.gray50,
    fontSize: fontSizes.other.chartXYAxis,
  },
};

const HCDefaultAxisOptions: Highcharts.AxisOptions = {
  labels: {
    style: {
      color: colors.gray700,
      fontSize: fontSizes.other.chartXYAxis,
    },
  },
};

export const HCDefaultXAxisOptions: Highcharts.XAxisOptions = {
  ...(HCDefaultAxisOptions as Highcharts.XAxisOptions),
};

export const HCDefaultYAxisOptions: Highcharts.YAxisOptions = {
  ...(HCDefaultAxisOptions as Highcharts.YAxisOptions),
  gridLineColor: colors.gray300,
  gridLineDashStyle: 'ShortDot',
};

export const HCDefaultAreasplineOptions: Highcharts.PlotAreasplineOptions = {
  marker: {
    enabled: false,
    lineColor: 'transparent',
    symbol: 'circle',
    radius: 4,
  },
  zoneAxis: 'x',
};

export const HCDefaultPlotOptions: Highcharts.PlotOptions = {
  series: {
    states: {
      hover: {
        halo: null,
      },
    },
  },
};

export default Highcharts;
