export type CrossProps = {
  size?: number;
};

export const Cross = ({ size = 16 }: CrossProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 16 16`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="12.4213"
      height="1.03511"
      rx="0.517553"
      transform="matrix(0.7072 0.707013 -0.7072 0.707013 4.34766 3.51984)"
      fill="#2D2F30"
    />
    <rect
      width="12.4213"
      height="1.03511"
      rx="0.517553"
      transform="matrix(-0.7072 0.707013 -0.7072 -0.707013 12.8281 4.25171)"
      fill="#2D2F30"
    />
  </svg>
);
