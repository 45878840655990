import colors from 'constants/colors';
import styled from 'styled-components';

export const StyledSingleDatePickerWrapper = styled.div`
  .SingleDatePickerInput,
  .SingleDatePickerInput .DateInput,
  .SingleDatePickerInput .DateInput > * {
    background-color: ${colors.lightGrey};
  }

  .SingleDatePickerInput {
    width: 270px;
    border-radius: 4px;

    & .SingleDatePickerInput_calendarIcon {
      padding: 4px 0;
      margin: 2px 8px 0 4px;
    }
    & .SingleDatePickerInput_clearDate {
      padding: 3px 0;
      display: flex;

      & svg {
        fill: ${colors.black};
      }
    }

    & .CalendarDay.CalendarDay__selected {
      border-radius: 50%;
    }

    & .DayPicker_transitionContainer {
      height: 275px !important;
    }

    & .DateInput_input {
      width: 230px;
    }
  }

`;
