import styled from 'styled-components';

import { BREAKPOINT_DESKTOP_START } from 'constants/breakpoints';
import hexToRgba from 'hex-to-rgba';

import colors from 'constants/colors';
import { resizeAnimation } from 'modules/common-ui/styled-components/index.css';

// const height = `35px`;
const padding = '8px';

export const Container = styled.div`
  width: 100%;
`;

export const Select = styled.div<{
  light?: boolean;
  inputType?: boolean;
  isFocused?: boolean;
  disabled?: boolean;
}>`
  align-items: center;
  border-radius: 5px;
  color: ${colors.gray850};
  color: ${colors.gray850};
  display: flex;
  font-size: 14px;
  height: 32px;
  justify-content: space-between;
  line-height: 18px;
  transition: background 120ms ease-in 0s;
  z-index: 2;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    height: 32px;
    width: 100%;
  }

  ${(props) =>
    !props.light &&
    `
      border: 1px solid ${colors.gray300};
      background: ${colors.white};
    `};

  :hover {
    cursor: pointer;
    background: rgba(45, 47, 48, 0.08);
  }

  :active {
    background: rgba(45, 47, 48, 0.16);
  }

  ${(props) => (props.light && props.isFocused ? 'background: rgba(45, 47, 48, 0.08);' : '')};

  ${(props) =>
    props.inputType &&
    ` 
      background: ${colors.gray50};
      border: 0.5px solid ${colors.gray300};
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      :hover {
        background: ${colors.gray50};
      }

      ${
        props.isFocused &&
        `
          background: ${colors.gray50};
          border-color: ${colors.blue100};
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 0 0.15rem ${hexToRgba(colors.blue400, 0.25)};
        `
      } 
    `}

  ${(props) =>
    props.disabled &&
    `
      color: ${colors.gray500};
      :hover {
        cursor: not-allowed;
      }
    `};
`;

export const Left = styled.div`
  padding: ${padding};
  white-space: nowrap;
  user-select: none;
`;

export const Ellipsis = styled.div`
  text-overflow: ellipsis;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  width: 80px;
`;

export const Right = styled.div`
  padding: 7px 8px 7px 8px;
  user-select: none;
  width: 32px;
  text-align: center;
`;

export const Options = styled.div<{
  width?: number;
  openTop?: boolean;
}>`
  background: ${colors.white};
  border-radius: 5px;
  border: 1px solid ${colors.gray300};
  box-shadow: 0 9px 24px rgba(15, 15, 15, 0.2), 0 3px 6px rgba(15, 15, 15, 0.1),
    0 0 0 rgba(15, 15, 15, 0.05);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${(props) => props.openTop && 'bottom: 20px;'}
  ${(props) => props.width && `width: ${props.width}px;`}
  padding: 8px 0;
  animation-name: ${() => resizeAnimation};
  animation-duration: 200ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in;
  min-width: 100%;
`;

export const Option = styled.div<{ disabled?: boolean; selected?: boolean }>`
  display: flex;
  transition: background 120ms ease-in 0s;
  white-space: normal;
  color: ${colors.gray850};
  ${(props) => (props.disabled ? 'cursor: not-allowed;' : 'cursor: pointer;')}
  padding-left: 7px;
  padding-right: 7px;
  align-items: center;

  :hover {
    background: rgba(45, 47, 48, 0.08);
  }
  :active {
    background: rgba(45, 47, 48, 0.16);
  }
  ${(props) => props.selected && `background: rgba(45, 47, 48, 0.05);`}

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    padding: 8px 16px;
  }
`;

export const Label = styled.div<{ variant?: 'default' | 'danger' }>`
  ${(props) => `color: ${props.variant === 'danger' ? colors.red500 : colors.gray850};`}
  font-size: 14px;
  user-select: none;
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media screen and (max-width: ${BREAKPOINT_DESKTOP_START}px) {
    font-size: 16px;
    line-height: 24px;
  }
`;
