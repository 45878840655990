export default {
  headings: {
    h1: '36px',
    h2: '28px',
    h3: '20px',
    h4: '16px',
    h5: '14px',
  },
  body: {
    short01: '14px',
    short02: '16px',
    long01: '14px',
    long02: '16px',
  },
  table: {
    subRaw: '12px',
    header: '14px',
    tail: '14px',
    default: '14px',
  },
  other: {
    caption: '12px',
    legal: '11px',
    overline: '11px',
    overlineMedium: '11px',
    code01: '12px',
    code02: '14px',
    chartXYAxis: '0.75rem',
  },
};
