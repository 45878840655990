import { Placement, createPopper } from '@popperjs/core';
import { MutableRefObject, useEffect } from 'react';

export const usePositionedElement = (
  display: boolean,
  parentElement: MutableRefObject<HTMLDivElement | null>,
  childElement: MutableRefObject<HTMLDivElement | null>,
  align: 'left' | 'right' = 'left',
  placement?: Placement,
) => {
  useEffect(() => {
    if (display && parentElement.current && childElement.current) {
      const parentWidth = parentElement.current.offsetWidth;
      childElement.current.style.minWidth = `${parentWidth}px`;
      childElement.current.style.removeProperty('width');
      const popperInstance = createPopper(
        parentElement.current,
        childElement.current,
        {
          placement: placement
            ? placement
            : align === 'right'
              ? 'bottom-end'
              : 'bottom-start',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ],
        },
      );

      return () => {
        popperInstance.destroy();
      };
    }
  }, [display, parentElement, childElement, align, placement]);
};
