import colors from 'constants/colors';

type CalendarIconProps = {
  size?: number;
  color?: string;
};
export const CalendarIcon = ({
  size = 16,
  color = colors.neutral850,
}: CalendarIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect x="2.5" y="3.5" width="11" height="10" rx="1.5" stroke={color} />
      <line x1="2" y1="6.5" x2="14" y2="6.5" stroke={color} />
      <line
        x1="5.5"
        y1="2.5"
        x2="5.5"
        y2="4.5"
        stroke={color}
        strokeLinecap="round"
      />
      <line
        x1="10.5"
        y1="2.5"
        x2="10.5"
        y2="4.5"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
};
